.container {
    margin-top: 20px;
    background-color: #f8f9fa;
  }
  
  .import-section {
    margin-top: 20px;
    padding: 20px;
    border-top: 1px solid #ccc;
  }

 